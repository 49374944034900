var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',_vm._l((_vm.selectedBtnList),function(item,i){return _c('v-btn',{key:i,class:item.value === _vm.selectedBtn
                ? 'activeBtnClass text-capitalize mr-2'
                : 'notActiveBtnClass text-capitalize mr-2',attrs:{"width":"150px"},on:{"click":function($event){_vm.selectedBtn = item.value}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)])],1),_c('br'),_c('br')],1),_c('div',[_c('v-data-table',{staticClass:"elevation-8",attrs:{"fixed-header":"","hide-default-footer":"","headers":_vm.header,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(props.item.checklist.name != 'Tickets')?_c('tr',[(
              props.item.checklist.name == 'First Call' &&
              (props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff')
            )?_c('td',{staticClass:"text-center py-4"},[_c('v-checkbox',{attrs:{"color":"#38227A","disabled":""},on:{"change":function($event){return _vm.updateStatus(props.item.is_completed, props.item.id)}},model:{value:(props.item.is_completed),callback:function ($$v) {_vm.$set(props.item, "is_completed", $$v)},expression:"props.item.is_completed"}})],1):_c('td',{staticClass:"text-center py-4"},[(
                props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff'
              )?_c('v-checkbox',{attrs:{"color":"#38227A"},on:{"change":function($event){return _vm.updateStatus(props.item.is_completed, props.item.id)}},model:{value:(props.item.is_completed),callback:function ($$v) {_vm.$set(props.item, "is_completed", $$v)},expression:"props.item.is_completed"}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[_c('div',{staticStyle:{"color":"#757575"}},[_c('div',[_vm._v(" "+_vm._s(props.item.checklist.name)+" ")]),_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(props.item.checklist.description)+" ")])])]),_c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(props.item.due_date)+" ")]),(props.item.status_str)?_c('div',[_c('v-chip',{class:`${props.item.status_str.toUpperCase()}`,attrs:{"small":""}},[_vm._v(" "+_vm._s(props.item.status_str)+" ")])],1):_vm._e()]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.completion_date_str)+" "),(props.item.assemblies!=null)?_c('div',[_vm._v("Assembly - "+_vm._s(props.item.assemblies[0].date))]):_vm._e()]),_c('td',[(props.item.action_dates.length > 0)?_c('div',{staticStyle:{"margin-left":"30%"}},[_c('ul',_vm._l((props.item.action_dates),function(actionDate){return _c('li',{key:actionDate.id},[_vm._v(" "+_vm._s(actionDate.action_date_str)+" ")])}),0)]):_vm._e()]),_c('td',{staticClass:"text-center"},[(
               ( props.item.user_type == 'admin' ||
                props.item.user_type == 'Sales Representative' ||
                props.item.user_type == 'Event Support Staff')&&
          !(props.item.checklist.action_name === 'Intro Email' && !_vm.introFlag)
              )?_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"border":"1px solid #4b18e5","border-radius":"10px"},attrs:{"text":""},on:{"click":function($event){return _vm.takeAction(props.item.checklist.action_key)}}},[_c('span',{staticStyle:{"color":"#4b18e5"},domProps:{"textContent":_vm._s(props.item.checklist.action_name)}})]):_vm._e()],1)]):_vm._e()]}}])})],1),_c('hotel-info-modal'),_c('intro-email-modal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex pb-4"},[_c('div',{staticClass:"ticketTextHeading"},[_vm._v("Checklist (Things to do)")]),_c('div',{staticClass:"ticketTextLine"})])
}]

export { render, staticRenderFns }